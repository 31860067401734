@import './components/Nav/Nav.scss';

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}
