@import "../Styles/mixins";
// Image Gallery
.gallery {
  padding: 20px;
  position: relative;
  user-select: none; /* Prevent text selection during drag */

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .scroll-buttons {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    pointer-events: none;

    .scroll-button {
      background: rgba(255, 255, 255, 0.8);
      border: none;
      padding: 10px;
      cursor: pointer;
      pointer-events: all;
      z-index: 1;
    }

    .left {
      left: 0;
    }

    .right {
      right: 0;
    }
  }

  .gallery-container {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    cursor: grab; /* Change cursor to indicate draggable area */
  }

  .gallery-container:active {
    cursor: grabbing; /* Change cursor during dragging */
  }

  .gallery-item {
    flex: 0 0 0;
    

  
    @include mobile {
      width: 100%; /* Full width on mobile */
      img {
        width: 100px;
        height: 150px;
        display: block;
      }
    }

    @include tablet {
      width: 50%; /* Two items per row on tablet */
      img {
        width: 150px;
        height: 200px;
        display: block;
      }
    }

    @include desktop {
      width: calc(33.333% - 10px); /* Three items per row on desktop */
      img {
        width: 300px;
        height: 450px;
        display: block;
      }
    }
  }
}
// Video Gallery

// Video Gallery
.featured-video {
  margin-bottom: 20px;
}

.video-reels {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @include mobile {
    .video-reel {
      width: 100%; 
      
    }
  }

  @include tablet {
    .video-reel {
      width: 100%; 
    }
  }

  @include desktop {
    .video-reel {
      width: calc(33.333% - 10px); 
    }
  }
}