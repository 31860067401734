// // src/components/Nav.scss
// .nav {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     background: #333;
//     padding: 10px 20px;
//     color: white;
  
//     h3 {
//       margin: 0;
//     }
  
//     .nav-links {
//       display: flex;
//       list-style: none;
      
//       li {
//         margin: 0 10px;
        
//         a {
//           color: white;
//           text-decoration: none;
          
//           &:hover {
//             text-decoration: underline;
//           }
//         }
//       }
//     }
  
//     // .social-media-icons {
//     //   /* Add styles for social media icons */
//     // }
//   }
@import '../../Styles/mixins';

.header {
  width: 100%;
  background-color: #333;
  color: #fff;

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    
    .logo h3 {
      margin: 0;
    }

    .nav-toggle {
      display: none;
      background-color: transparent;
      border: none;
      color: #fff;
      font-size: 1.5rem;
      cursor: pointer;
    }

    .nav-links {
      display: flex;
      list-style: none;
      gap: 1rem;

      li {
        a {
          color: #fff;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .social-media-icons {
      display: flex;
      gap: 1rem;
      /* Add your social media icon styles here */
    }

    @include mobile {
      .nav-toggle {
        display: block;
      }

      .nav-links {
        display: none;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        text-align: center;

        li {
          width: 100%;
          
          a {
            display: block;
            width: 100%;
            padding: 1rem 0;
          }
        }
      }

      .social-media-icons {
        display: none;
      }

      &.open {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        overflow-y: auto;
        background-color: rgba(0, 0, 0, 0.9);
        z-index: 1000;

        .nav-links {
          display: flex;
        }

        .social-media-icons {
          display: flex;
          width: 100%;
          text-align: center;
          padding: 1rem 0;
        }
      }
    }

    @include tablet {
      .nav-links {
        gap: 0.75rem;
      }
    }

    @include desktop {
      .nav-links {
        gap: 1rem;
      }
    }
  }
}