.home {

  section {
    margin: 20px 0;
    padding: 20px;
    border-bottom: 1px solid #ccc;

    h2 {
      margin-top: 0;
    }
  }
}
